import {ref} from "vue";

export function useMessage() {
    const message = ref(null);
    const hide = (after = 0) => {
        return setTimeout(() => {
            message.value = null;
        }, after*1000);
    }
    const show = (msg, during = 0) => {
        message.value = msg;
        if (during) {
            return hide(during);
        }
    }

    /**
     *
     * @param {string} msg
     * @param {number} after Le nombre de secondes après lesquelles afficher le message
     * @param {number} during La durée d'affichage du message
     * @return {number}
     */
    const showAfter = (msg, after = 5, during = 5) => {
        return setTimeout(() => {
            show(msg, during);
        }, after*1000);
    }

    return {message, showAfter, show, hide}

}
