<template>
  <div class="pt-2">
    <div class="relative border border-gray-300 rounded-md px-3 py-2 shadow-sm focus-within:ring-1 focus-within:ring-indigo-600 focus-within:border-indigo-600"
      :class="{
        'focus-within:ring-red-600': error || errors.length > 0,
        'focus-within:border-red-600': error || errors.length > 0
      }"
    >
      <span v-if="cornerHint" class="absolute -top-2 right-2 -mt-px inline-block px-1 bg-white text-xs font-medium text-gray-900" id="email-optional">{{ cornerHint }}</span>

      <label
        :for="$attrs.id ? $attrs.id : null"
        class="absolute -top-2 left-2 -mt-px inline-block px-1 bg-white text-xs font-medium text-gray-900"
        :class="{
          'text-red-600': error || errors.length > 0
        }"
      >{{ label || name }}</label>
      <input
        :type="type"
        :name="name"
        :id="$attrs.id ? $attrs.id : null"
        :placeholder="$attrs.placeholder ? $attrs.placeholder : null"
        v-model="inputValue"
        :required="$attrs.required ? $attrs.required : null"
        :autocomplete="$attrs.autocomplete ? $attrs.autocomplete : null"
        class="block w-full border-0 p-0 text-gray-900 placeholder-gray-500 focus:ring-0 sm:text-sm"
        @change="emit('change', $event)"
        @input="emit('input', $event)"
        :class="{
          'border-red-300': error || errors.length > 0,
          'text-red-800': error || errors.length > 0,
          'placeholder-red-300': error || errors.length > 0,
          'focus:outline-none': error || errors.length > 0,
          'focus:ring-red-500': error || errors.length > 0,
          'focus:border-red-500': error || errors.length > 0,
        }"
      />
      <div class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none" v-if="error || errors.length > 0">
        <ExclamationCircleIcon class="h-5 w-5 text-red-500" aria-hidden="true" />
      </div>
    </div>
    <div class="text-right text-gray-500 cursor-pointer" v-if="originalType === 'password'" @click="showPassword = !showPassword">
      <span v-if="originalType === 'password' && !showPassword">
        <small class="mr-1">Afficher le mot de passe</small>
        <EyeIcon class="h-5 w-5 inline" aria-hidden="true" />
      </span>
      <span v-if="originalType === 'password' && showPassword">
        <small class="mr-1">Masquer le mot de passe</small>
        <EyeSlashIcon class="h-5 w-5 inline " aria-hidden="true" />
      </span>
    </div>
    <div v-if="error" class="mt-2 text-sm text-red-600 text-left">
      <ExclamationCircleIcon class="h-5 w-5 text-red-500 inline -mt-1" aria-hidden="true" />
      {{error}}
    </div>
    <div v-if="errors" v-for="err in errors" class="mt-2 text-sm text-red-600 text-left">
      <ExclamationCircleIcon class="h-5 w-5 text-red-500 inline -mt-1" aria-hidden="true" />
      {{err}}
    </div>
    <div v-if="warnings" v-for="warn in warnings" class="mt-2 text-sm text-orange-600 text-left">
      <ExclamationCircleIcon class="h-5 w-5 text-orange-500 inline -mt-1" aria-hidden="true" />
      {{warn}}
    </div>
  </div>
</template>
<script setup>
import {ref, computed, toRef, toRefs, useAttrs} from 'vue';
import ExclamationCircleIcon from '@heroicons/vue/24/solid/ExclamationCircleIcon.js'
import EyeIcon from '@heroicons/vue/24/solid/EyeIcon.js'
import EyeSlashIcon from '@heroicons/vue/24/solid/EyeSlashIcon.js'


const attrs = useAttrs();

const {attrValue} = toRef(attrs, 'value');

const props = defineProps({
  label: {type: String},
  type: {type: String, default: "text" },
  name: {type: String, default: ''},
  modelValue: {type: String, default: null},
  value: {type: String, default: null},
  error: {type: String, default: null},
  errors: {type: Array, default: () => []},
  warnings: {type: Array, default: () => []},
  cornerHint: {type: String, default: null}
})
const emit = defineEmits(['change', 'update:modelValue', 'input']);
const {id, label, error, modelValue, name} = toRefs(props);
const originalType = toRef(props, 'type')
const showPassword = ref(false);

const type = computed(() => {
  if (originalType.value === 'password' && showPassword.value) {
    return 'text';
  }
  return originalType.value;
})

const inputValue = computed({
  get() {
    return modelValue.value || props.value;
  },
  set(newVal) {
    emit('update:modelValue', newVal, name.value)
  }
})

// const emitInput = ($event) => {
//   emit('input', $event);
//   emit('update:value', $event.target.value);
//   emit('update:modelValue', $event.target.value);
// }

</script>
